import React, {useEffect, useRef, useState} from 'react';
import {ThreeElements, useFrame, useLoader} from '@react-three/fiber';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {USDZLoader} from 'three/examples/jsm/loaders/USDZLoader';

import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Mesh, TextureLoader} from "three";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";

interface ModelLoaderProps {
    url: string;
    format: 'glb' | 'gltf' | 'usdz';
    onLoad: () => void;
}

export const ModelLoader = ({url, format, onLoad}: ModelLoaderProps) => {
    const [model, setModel] = useState<any>(null);
    useEffect(() => {
        let loader;
        switch (format) {
            case 'glb':
            case 'gltf':
                loader = new GLTFLoader();
                break;
            case 'usdz':
                loader = new USDZLoader();
                break;
            default:
                throw new Error(`Unsupported format: ${format}`);
        }

        loader.load(url, (loadedModel) => {
            setModel(loadedModel);
            onLoad();

        });
    }, [url, format, onLoad]);

    return model ? <primitive object={model.scene}/> : null;
};

export const ModelJPG = ({objUrl, textureUrl}: { objUrl: string; textureUrl: string }) => {
    const obj = useLoader(OBJLoader, objUrl);
    const texture = useLoader(TextureLoader, textureUrl);

    useEffect(() => {
        obj.traverse((child) => {
            if (child instanceof Mesh) {
                child.material.map = texture;
            }
        });
    }, [obj, texture]);

    return <primitive object={obj}/>;
}

export const Model = ({objUrl, mtlUrl}: { objUrl: string; mtlUrl: string }) => {
    const materials = useLoader(MTLLoader, mtlUrl);
    const obj = useLoader(OBJLoader, objUrl, (loader) => {
        materials.preload();
        loader.setMaterials(materials);
    });

    return <primitive object={obj}/>;
}

export const Box = (props: ThreeElements['mesh']) => {
    const meshRef = useRef<Mesh>(null!)
    const [hovered, setHover] = useState(false);
    const [active, setActive] = useState(false);

    useFrame(() => {
        meshRef.current.rotation.x += 0.01;
        meshRef.current.rotation.y += 0.01;
    });
    return (
        <mesh
            {...props}
            ref={meshRef}
            scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}
        >
            <boxGeometry args={[1, 1, 1]}/>
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'}/>
        </mesh>
    )
}
