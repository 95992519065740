import React, {useEffect, useRef, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {Mesh} from 'three';
import {Loader} from './components/Loader';
import {OrbitControls} from '@react-three/drei';
import {CoordinateField} from "./components/CoordinateField";
import {ModelLoader, ModelJPG, Model} from "./components/Models";
import {createXRStore, XR} from "@react-three/xr";

const App: React.FC = () => {
    const store = createXRStore();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleModelLoad = () => {
        setIsLoading(false);
    };


    const maxPixelRatio = 2; // Set a maximum pixel ratio
    const pixelRatio = Math.min(window.devicePixelRatio, maxPixelRatio);

    const [isImmersiveVRSupported, setIsImmersiveVRSupported] = useState<boolean | null>(null);

    useEffect(() => {
        if (navigator.xr) {
            navigator.xr.isSessionSupported('immersive-vr')
                .then((supported) => {
                    setIsImmersiveVRSupported(supported);
                })
                .catch((error) => {
                    console.error('Error checking for immersive VR support', error);
                    setIsImmersiveVRSupported(false);
                });
        } else {
            setIsImmersiveVRSupported(false);
        }
    }, []);

    const modelRef = useRef<Mesh>(null);
    return (
        <div className="h-full w-full">
            {isLoading && <Loader/>}
            <>
                {isImmersiveVRSupported === null ? (
                    <p>Checking for immersive VR support...</p>
                ) : isImmersiveVRSupported ? (
                    <button onClick={() => store.enterAR()}>Enter AR</button>
                ) : (
                    <p>Immersive VR is not supported.</p>
                )}
                <Canvas className="w-full h-full" gl={{pixelRatio}}>
                    <XR store={store}>
                        <ambientLight intensity={Math.PI / 2}/>
                        <spotLight position={[100, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI}/>
                        <pointLight position={[-100, -10, -10]} decay={0} intensity={Math.PI}/>
                        back

                        {/*MODELS*/}
                        {/*<ModelJPG objUrl="/models/house.obj" textureUrl="/models/20240506_091241.jpg"/>*/}
                        {/*<Model objUrl="/models/house.obj" mtlUrl="/models/house.mtl" />*/}
                        {/*<Model objUrl="/models/obj/StrawberryHouse_ArchiPack.obj" mtlUrl="/models/house.mtl" />*/}
                        {/*<ModelLoader url="/models/glb/museumtrajes.glb" format="glb" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/glb/smart_art_gallery_space.glb" format="glb" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/glb/vr_gallery.glb" format="glb" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/glb/StrawberryHouse_ArchiPack.glb" format="glb" onLoad={handleModelLoad}/>*/}
                        <ModelLoader url="/models/glb/StrawberryHouse.glb" format="glb" onLoad={handleModelLoad}/>
                        {/*<ModelLoader url="/models/glb/test.glb" format="glb" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/gltf/test.gltf" format="gltf" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/usdz/MuseumTrajes.usdz" format="usdz" onLoad={handleModelLoad}/>*/}
                        {/*<ModelLoader url="/models/gltf/museumtrajes/scene.gltf" format="gltf" onLoad={handleModelLoad}/>*/}

                        <OrbitControls/>
                    </XR>
                </Canvas>
                {/*<CoordinateField position={modelRef.current?.position.toArray() || [0, 0, 0]}/>*/}
            </>
        </div>
    );
};

export default App;